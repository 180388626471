<template>
    <section>
        <div class="row mx-0 align-items-center border-bottom" style="height:50px;">
            <div class="col-auto f-22 text-general f-600">
                Historial de turno
            </div>
            <div class="bg-light-f5 border br-20 px-3 text-general">
                <b>45</b>
                Servicios Totales
            </div>
            <div class="bg-light-f5 border br-20 px-3 ml-2 text-general">
                <b>10</b>
                en el último mes
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 125px)">
            <div v-for="data in 4" :key="data" class="row mx-2 mb-2 py-3 bg-white text-general br-4 shadow cr-pointer" @click="ver()">
                <div class="col-auto text-general f-600 f-18">
                    No. 58452
                </div>
                <div class="col px-0 text-center">
                    <i class="icon-fecha-fin text-general f-18" />
                    10 Ago. 2021
                </div>
                <div class="col text-center tres-puntos">
                    <i class="icon-clock text-general f-18" />
                    10:00 a.m. - 06:00 p.m
                </div>
                <div class="col-auto text-center">
                    <i class="icon-route text-general f-18" />
                    6
                </div>
            </div>
            <div class="row mx-0 my-3 mt-4 justify-center">
                <div class="bg-general3 text-white br-20 px-4 cr-pointer">
                    Ver más
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            verHistorial: false
        }
    },
    methods: {
        ver(){
            this.verHistorial = !this.verHistorial
            this.$emit('verHistorial', this.verHistorial);
        }
    }
}
</script>
